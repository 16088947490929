const txt = [
  [
    "Я принесу выпить для нас. Моя очередь.",
    "I will get drinks for us. It's my turn.",
  ],
  ["подождите меня несколько минут", "Wait for me a few minuntes"],
  ["вот ваши напитки. ", "Here are your drinks"],
  [
    "ты любитель вечеринок. Отлично. Я не против.",
    "You're a party animal. Cool. I'm down.",
  ],
  ["всегда пожалуйста, Петр", "Anytime, Peter"],
];

export default txt;
