const txteng10 = [
  ["клянуь звонить каждый день", "I swear to call every day"],
  ["она всегда грызет ногти", "She always bites his fingernails"],
  ["проснись и пой (вставай, соня)", "Rise and shine sleepy head"],
  ["Он никогда не водил машину", "He's never driven a car (he has)"],
  ["Что ты одевал вчера?", "What did you wear yesterday?"],
  ["Кот прячется под диваном", "The cat is hidding under the sofa"],
  ["Когда собака укусила вас?", "When did the dog bite you?"],
  ["Солнце встало 10 минут назад", "The sun rose ten minutes ago"],
  [
    "Я ездил на работу на автобусе на прошлой неделе",
    "I rode to work on the bus last week",
  ],
  ["Смотри, ты порвал свои джинсы", "Look, you've torn your jeans"],
  ["Куда вы едете (за рулем)?", "Where are you driving?"],
  ["Я вчера катался на велосипеде", "I rode the bicycle yesterday"],
  [
    "Ты когда-нибудь ездил на велосипеде?",
    "Have you ever been ridden the bicycle",
  ],
  ["Я не знаю, что одеть сегодня", "I don't know what to wear today"],
  ["Мы еще не написали ничего", "We haven't written anything yet"],
  ["Просто надень то, что ты всегда носишь", "Just wear what you always wear"],
  [
    "Он встал с дивана, порвал фотографию и выругался",
    "He rose from the sofa, tore the picture and swore",
  ],
  ["Пожалуйста, не ругайся при нас", "Please, don't swear in front of us"],
  [
    "Я научился кататься на велосипеде в 6 лет",
    "I learned to ride a bike when I was six",
  ],
  ["Солнце еще не взошло", "The sun hasn't risen yet"],
  [
    "Он отвозил меня на работу каждое утро",
    "He drove me to work every morning",
  ],
  [
    "Она только что спрятала порванную книгу",
    "She has just hidden the torn book",
  ],
  ["Блин, меня что-то укусило", "Blast! Something has bitten me"],
  ["Они часто ездили на такси", "They often rode in a taxy"],
  [
    "В прошлом месяце я ездил (за рулем) в Даллас",
    "I drove to Dallas last month",
  ],
  ["Ай. Она (собака) укусила меня", "Oush! It's bitten me (It has)"],
  ["Я носил очки, когда был маленьким", "I wore glasses when I was a kid"],
  ["Не ругайся при детях", "Don't swear in front of the kids"],
  ["Я еще не написал", "I haven't written yet"],
  ["Она сейчас катается на велосипеде", "She's riding the bike"],
  ["Он быстро встал, чтобы помочь ей", "He quickly rose to help her"],
  ["Блин! Я порвал футболку", "Blast! I've torn my T-shirt"],
  ["Я еще ничего не спрятал", "I haven't hidden anything yet"],
  ["Во что он одет?", "What is he wearing?"],
  ["Кто написал эту программу?", "Who wrote the code?"],
  ["Цены уже поднялись на 10%", "Prices have risen (by) ten persent"],
  ["Куда ты ездил вчера (за рулем)?", "Where did you drive yesterday?"],
  ["Любая собака может укусить", "Any dog can bite"],
  ["Клянусь, он знает это", "I swear he knows it"],
  [
    "Ты едешь на поезде? Нет, на машине (за рулем)",
    "Are you going by train? No, I'm driving",
  ],
  ["Я всегда прячу деньги в книгах", "I always hide my money in the books"],
  ["Зачем ты порвал фотки?", "Why did you tear the pics?"],
  [
    "У меня не было машины в то время, поэтому я ездил на автобусе каждый день",
    "I didn't have a car at that time so I rode the bus every day",
  ],
  ["Я учусь водить", "I learning to drive"],
];

export default txteng10;
