import m0 from "./0.mp3";
import m1 from "./1.mp3";
import m2 from "./2.mp3";
import m3 from "./3.mp3";
import m4 from "./4.mp3";
import m5 from "./5.mp3";
import m6 from "./6.mp3";
import m7 from "./7.mp3";
import m8 from "./8.mp3";
import m9 from "./9.mp3";
import m10 from "./10.mp3";
import m11 from "./11.mp3";
import m12 from "./12.mp3";
import m13 from "./13.mp3";
import m14 from "./14.mp3";
import m15 from "./15.mp3";
import m16 from "./16.mp3";
import m17 from "./17.mp3";
import m18 from "./18.mp3";
import m19 from "./19.mp3";
import m20 from "./20.mp3";
import m21 from "./21.mp3";
import m22 from "./22.mp3";
import m23 from "./23.mp3";
const mp3 = [
  m0,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  m8,
  m9,
  m10,
  m11,
  m12,
  m13,
  m14,
  m15,
  m16,
  m17,
  m18,
  m19,
  m20,
  m21,
  m22,
  m23,
];
export default mp3;
