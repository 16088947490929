const song4 = [
  ["Я так далеко", "I’m so far away"],
  ["От всего, что ты знаешь,", "From everything you know"],
  ["Твое имя несется по ветру,", "Your name is carried on the wind"],
  ["Твои ледяные голубые воды, Аня.", "Your ice blue waters Anya"],
  ["Куда они текут?", "Where do they flow"],
  ["Где они были?", "Where have they been"],
  ["Скрытая страсть,", "A hidden passion"],
  ["Прикосновение к искре,", "Touching a spark"],
  ["Пламя революции,", "Flame of revolution"],
  ["Пылающее в твоем цыганском сердце,", "Burning wild in your gypsy heart"],
  ["Твоем цыганском сердце.", "Your gypsy heart"],
  ["Аня-дух свободы,", "Anya — The spirit of freedom"],
  ["Свет свободы,", "The light of freedom buried"],
  ["Погребенный глубоко в твоей душе,", "Deep within your soul"],
  ["Через пустоту, чтобы увидеть,", "Across the puszta plain to see"],
  ["Как рапсодия ангелов", "The rhapsody of angels"],
  ["Отказывается танцевать в одиночестве.", "Refuse to dance alone"],
  ["Что они значат?", "What do they mean"],
  ["Твое сокровище", "Your jewel flashing"],
  ["Мелькает в темноте,", "Across the dark"],
  ["Запретное расстояние,", "Forbidding distance"],
  ["Я люблю твое цыганское сердце,", "I love your gypsy heart"],
  ["Твое цыганское сердце.", "Your gypsy heart"],
  ["Аня-дух свободы,", "Anya — The spirit of freedom"],
];

export default song4;
