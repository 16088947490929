const txteng10 = [
  ["когда вы были ..? (вчера)", "When were you ..?"],
  ["почему они ..? (вчера)", "Why did they ..?"],
  ["как он ..? (вчера)", "How did he ..?"],
  ["Куда ты ..? (вчера)", "Where did you ..?"],
  ["Что ты ..? (вчера)", "What did you ..?"],
  ["Почему это было ..?", "Why was it ..?"],
  ["Когда вы ..?", "When did you ..?"],
  ["Где он был ..?", "Where was he ..?"],
  ["Как мы ..? (вчера)", "How did we ..?"],
  ["Что они ..? (вчера)", "What did they ..?"],
  ["Когда они ..?", "When did they ..?"],
  ["Где вы были ..?", "Where were you ..?"],
  ["Как она ..? (вчера)", "How did she ..?"],
  ["Что она ..? (вчера)", "What did she ..?"],
];

export default txteng10;
