const txteng4 = [
  ["Он часто отправляет.", "He often sends."],
  ["Я редко беру это.", "I rarely take it."],
  ["Она обычно начинает там.", "She usually begins there."],
  ["Мы иногда даем.", "Sometimes we give."],
  ["она часто учит меня здесь.", "She often teaches me here"],
  ["он обычно ходит туда каждый день.", "He usually goes there every day."],
  ["мы никогда не плаваем там.", "We never swim there."],
  ["она никогда не прячется.", "She never hides."],
  ["Мы ремонтируем это каждый год.", "We fix it every year."],
  ["он часто ремонтирует здесь.", "He often fixes here."],
  ["вы никогда не летаете туда.", "You never fly there."],
  ["это никогда не работает.", "It never works."],
  ["Ты иногда берешь это.", "Sometimes You take it."],
  ["Он никогда не пьет здесь.", "He never drinks here."],
  ["Ты всегда прячешься там.", "You always hide there."],
  ["Я покупаю каждый день.", "I buy every day."],
  ["Она часто продает это там.", "She often sells it there."],
  ["Мы редко делаем это там.", "We rarely do it there."],
  ["Вы никогда не покупаете здесь.", "You never buy here."],
  ["Она никогда не ремонтирует там.", "She never fixes there."],
  ["Он часто выбирает это.", "He often chooses it."],
  ["Ты выбираешь это каждый месяц.", "You choose every month."],
  ["Это всегда работает.", "It always works."],
  ["Мы никогда не прячемся.", "We never hide."],
  ["Ты обычно плаваешь там.", "You usually swim there."],
  ["Они выбирают здесь.", "They choose here."],
  ["Она выбирает каждую неделю.", "She chooses every week."],
  ["Я редко выбираю это.", "I rarely choose it."],
  ["Она никогда не выбирает.", "She never chooses."],
  ["Мы часто встречаемся там.", "We often meet there."],
  ["Вы никогда не даете это.", "You never give it."],
  ["Это обычно работает.", "It usually works."],
  ["Они всегда дерутся здесь.", "They always fight here. "],
  ["Он часто учит меня.", "He often teaches me."],
  ["Она ходит сюда каждый день.", "She goes here every day."],
  ["Я редко хожу туда.", "I rarely go there."],
  ["Она всегда учит меня.", "She always teaches me."],
  ["Мы всегда готовим здесь.", "We always cook here."],
  ["Вы часто ходите сюда.", "You often go here. "],
  ["Это иногда работает.", "Sometimes it works."],
  ["Ты никогда не учишь меня.", "You never teach me."],
  ["Они иногда встречаются.", "Sometimes they meet."],
  ["Они встречаются каждый день.", "They meet every day."],
  ["Не сдавайся.", "Never give up."],
];

export default txteng4;
