import m0 from "./0.mp3";
import m1 from "./1.mp3";
import m2 from "./2.mp3";
import m3 from "./3.mp3";
import m4 from "./4.mp3";
import m5 from "./5.mp3";
import m6 from "./6.mp3";
import m7 from "./7.mp3";
const mp3 = [
  m0,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
];
export default mp3;
