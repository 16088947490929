const txteng2 = [
  ["я плохой.", "I am bad"],
  ["я больной", "I am ill"],
  ["я счастливый", "I am happy"],
  ["я хороший", "I am good"],
  ["я дома", "I am at home"],
  ["я на работе", "I am at work"],
  ["ты плохой.", "You are bad"],
  ["ты больной", "You are Youll"],
  ["ты счастливый", "You are happy"],
  ["ты хороший", "You are good"],
  ["ты дома", "You are at home"],
  ["ты на работе", "You are at work"],
  ["он плохой.", "He is bad"],
  ["он больной", "He is ill"],
  ["он счастливый", "He is happy"],
  ["он хороший", "He is good"],
  ["он дома", "He is at home"],
  ["он на работе", "He is at work"],
  ["она плохой.", "She is bad"],
  ["она больной", "She is ill"],
  ["она счастливый", "She is happy"],
  ["она хороший", "She is good"],
  ["она дома", "She is at home"],
  ["она на работе", "She is at work"],
  ["это плохой.", "It is bad"],
  ["это больной", "It is ill"],
  ["это счастливый", "It is happy"],
  ["это хороший", "It is good"],
  ["это дома", "It is at home"],
  ["это на работе", "It is at work"],
  ["мы плохой.", "We are bad"],
  ["мы больной", "We are ill"],
  ["мы счастливый", "We are happy"],
  ["мы хороший", "We are good"],
  ["мы дома", "We are at home"],
  ["мы на работе", "We are at work"],
  ["вы плохой.", "You are bad"],
  ["вы больной", "You are ill"],
  ["вы счастливый", "You are happy"],
  ["вы хороший", "You are good"],
  ["вы дома", "You are at home"],
  ["вы на работе", "You are at work"],
  ["они плохой.", "They are bad"],
  ["они больной", "They are ill"],
  ["они счастливый", "They are happy"],
  ["они хороший", "They are good"],
  ["они дома", "They are at home"],
  ["они на работе", "They are at work"], //--------
  ["я не плохой.", "I am not bad"],
  ["я не больной", "I am not ill"],
  ["я не счастливый", "I am not happy"],
  ["я не хороший", "I am not good"],
  ["я не дома", "I am not at home"],
  ["я не на работе", "I am not at work"],
  ["ты не плохой.", "You are not bad"],
  ["ты не больной", "You are not Youll"],
  ["ты не счастливый", "You are not happy"],
  ["ты не хороший", "You are not good"],
  ["ты не дома", "You are not at home"],
  ["ты не на работе", "You are not at work"],
  ["он не плохой.", "He is not bad"],
  ["он не больной", "He is not ill"],
  ["он не счастливый", "He is not happy"],
  ["он не хороший", "He is not good"],
  ["он не дома", "He is not at home"],
  ["он не на работе", "He is not at work"],
  ["она не плохой.", "She is not bad"],
  ["она не больной", "She is not ill"],
  ["она не счастливый", "She is not happy"],
  ["она не хороший", "She is not good"],
  ["она не дома", "She is not at home"],
  ["она не на работе", "She is not at work"],
  ["это не плохой.", "It is not bad"],
  ["это не больной", "It is not ill"],
  ["это не счастливый", "It is not happy"],
  ["это не хороший", "It is not good"],
  ["это не дома", "It is not at home"],
  ["это не на работе", "It is not at work"],
  ["мы не плохой.", "We are not bad"],
  ["мы не больной", "We are not ill"],
  ["мы не счастливый", "We are not happy"],
  ["мы не хороший", "We are not good"],
  ["мы не дома", "We are not at home"],
  ["мы не на работе", "We are not at work"],
  ["вы не плохой.", "You are not bad"],
  ["вы не больной", "You are not ill"],
  ["вы не счастливый", "You are not happy"],
  ["вы не хороший", "You are not good"],
  ["вы не дома", "You are not at home"],
  ["вы не на работе", "You are not at work"],
  ["они не плохой.", "They are not bad"],
  ["они не больной", "They are not ill"],
  ["они не счастливый", "They are not happy"],
  ["они не хороший", "They are not good"],
  ["они не дома", "They are not at home"],
  ["они не на работе", "They are not at work"], //--------
  ["я плохой?", "am I bad?"],
  ["я больной?", "am I ill?"],
  ["я счастливый?", "am I happy?"],
  ["я хороший?", "am I good?"],
  ["я дома?", "am I at home?"],
  ["я на работе?", "am I at work?"],
  ["ты плохой?", "are you bad?"],
  ["ты больной?", "are you Youll?"],
  ["ты счастливый?", "are you happy?"],
  ["ты хороший?", "are you good?"],
  ["ты дома?", "are you at home?"],
  ["ты на работе?", "are you at work?"],
  ["он плохой??", "Is he bad?"],
  ["он больной?", "Is he ill?"],
  ["он счастливый?", "Is he happy?"],
  ["он хороший?", "Is he good?"],
  ["он дома?", "Is he at home?"],
  ["он на работе?", "Is he at work?"],
  ["она плохой??", "Is she bad?"],
  ["она больной?", "Is she ill?"],
  ["она счастливый?", "Is she happy?"],
  ["она хороший?", "Is she good?"],
  ["она дома?", "Is she at home?"],
  ["она на работе?", "Is she at work?"],
  ["это плохой??", "Is it bad?"],
  ["это больной?", "Is it ill?"],
  ["это счастливый?", "Is it happy?"],
  ["это хороший?", "Is it good?"],
  ["это дома?", "Is it at home?"],
  ["это на работе?", "Is it at work?"],
  ["мы плохой??", "are we bad?"],
  ["мы больной?", "are we ill?"],
  ["мы счастливый?", "are we happy?"],
  ["мы хороший?", "are we good?"],
  ["мы дома?", "are we at home?"],
  ["мы на работе?", "are we at work?"],
  ["вы плохой??", "are you bad?"],
  ["вы больной?", "are you ill?"],
  ["вы счастливый?", "are you happy?"],
  ["вы хороший?", "are you good?"],
  ["вы дома?", "are you at home?"],
  ["вы на работе?", "are you at work?"],
  ["они плохой??", "They are bad?"],
  ["они больной?", "They are ill?"],
  ["они счастливый?", "They are happy?"],
  ["они хороший?", "They are good?"],
  ["они дома?", "They are at home?"],
  ["они на работе?", "They are at work?"], //--------
];

export default txteng2;
