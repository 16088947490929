const a_t12_2 = [
  ["Что ты понимаешь ?", "What d'you understand ?"],
  ["Когда ты обычно приходишь ?", "When d'you usually come ?"],
  ["Почему ты всегда ездишь туда?", "Why do you always go there?"],
  ["Куда она ходит?", "Where does she go?"],
  ["Что она знает ?", "What does she know ?"],
  ["Кого вы всегда встречаете?", "Who d'you always meet?"],
  ["Куда они часто летают?", "Where do they often fly?"],
  ["Где она живет?", "Where does she live?"],
  ["Что он хочет?", "What does he want?"],
  ["Как ты понимаешь это?", "How d'you understand it?"],
  ["Кто знает?", "Who knows?"],
  ["Кого ты знаешь?", "Who d'you know?"],
  ["Почему ты живешь здесь?", "Why d'you live here?"],
  ["Когда она работает?", "When does she work?"],
  ["Что они знают?", "What do they know?"],
  ["Почему она часто приезжает?", "Why does she often come?"],
  ["Когда они обычно покупают?", "When do they usually buy?"],
  ["Куда ты всегда приходишь?", "Where do you always come?"],
];

export default a_t12_2;
