const txt = [
  [
    "Мы должны закончить эту работу. Иначе наш босс разозлится на нас",
    "We have to finish this work. Otherwise our boss will mad at us",
  ],
  [
    "мы должны быть благодарны за эту работу",
    "we should be grateful for this job",
  ],
  ["она принимала лекарство?", "did she take medicine?"],
  ["ты можешь прикрыть мою смену?", "can you cover my shift?"],
  ["он мне еще не звонил", "he has not called me yet"],
  [
    "могу ли я получить свою зарплату вперед?",
    "can i get my salary in advance?",
  ],
  ["Замолчи. Ты ленивый", "Shut up. You are a lazy person."],
  ["Я должен заплатить арендную плату", "I need to pay the house rent"],
  ["Это не моя головная боль", "It's not my headache"],
];

export default txt;
