import m0 from "./0.mp3";
import m1 from "./1.mp3";
import m2 from "./2.mp3";
import m3 from "./3.mp3";
import m4 from "./4.mp3";
import m5 from "./5.mp3";
import m6 from "./6.mp3";
import m7 from "./7.mp3";
import m8 from "./8.mp3";
import m9 from "./9.mp3";
import m10 from "./10.mp3";
import m11 from "./11.mp3";
import m12 from "./12.mp3";
import m13 from "./13.mp3";
import m14 from "./14.mp3";
import m15 from "./15.mp3";
import m16 from "./16.mp3";
import m17 from "./17.mp3";
import m18 from "./18.mp3";
import m19 from "./19.mp3";
import m20 from "./20.mp3";
import m21 from "./21.mp3";
import m22 from "./22.mp3";
import m23 from "./23.mp3";
import m24 from "./24.mp3";
import m25 from "./25.mp3";
import m26 from "./26.mp3";
import m27 from "./27.mp3";
import m28 from "./28.mp3";
import m29 from "./29.mp3";
import m30 from "./30.mp3";
import m31 from "./31.mp3";
import m32 from "./32.mp3";
import m33 from "./33.mp3";
import m34 from "./34.mp3";
import m35 from "./35.mp3";
import m36 from "./36.mp3";
import m37 from "./37.mp3";
import m38 from "./38.mp3";
import m39 from "./39.mp3";
import m40 from "./40.mp3";
import m41 from "./41.mp3";
import m42 from "./42.mp3";
import m43 from "./43.mp3";
import m44 from "./44.mp3";
import m45 from "./45.mp3";
import m46 from "./46.mp3";
import m47 from "./47.mp3";
import m48 from "./48.mp3";
import m49 from "./49.mp3";
import m50 from "./50.mp3";
import m51 from "./51.mp3";
import m52 from "./52.mp3";
import m53 from "./53.mp3";
import m54 from "./54.mp3";
import m55 from "./55.mp3";
import m56 from "./56.mp3";
import m57 from "./57.mp3";
import m58 from "./58.mp3";
import m59 from "./59.mp3";
import m60 from "./60.mp3";
import m61 from "./61.mp3";
import m62 from "./62.mp3";
import m63 from "./63.mp3";
import m64 from "./64.mp3";
import m65 from "./65.mp3";
import m66 from "./66.mp3";
import m67 from "./67.mp3";
import m68 from "./68.mp3";
import m69 from "./69.mp3";
import m70 from "./70.mp3";
import m71 from "./71.mp3";
import m72 from "./72.mp3";
import m73 from "./73.mp3";
import m74 from "./74.mp3";
import m75 from "./75.mp3";
import m76 from "./76.mp3";
import m77 from "./77.mp3";
import m78 from "./78.mp3";
import m79 from "./79.mp3";
import m80 from "./80.mp3";
import m81 from "./81.mp3";
import m82 from "./82.mp3";
import m83 from "./83.mp3";
import m84 from "./84.mp3";
import m85 from "./85.mp3";
import m86 from "./86.mp3";
import m87 from "./87.mp3";
import m88 from "./88.mp3";
import m89 from "./89.mp3";
import m90 from "./90.mp3";
import m91 from "./91.mp3";
import m92 from "./92.mp3";
import m93 from "./93.mp3";
import m94 from "./94.mp3";
import m95 from "./95.mp3";
import m96 from "./96.mp3";
import m97 from "./97.mp3";
import m98 from "./98.mp3";
import m99 from "./99.mp3";
import m100 from "./100.mp3";
import m101 from "./101.mp3";
import m102 from "./102.mp3";
import m103 from "./103.mp3";
import m104 from "./104.mp3";
import m105 from "./105.mp3";
import m106 from "./106.mp3";
import m107 from "./107.mp3";
import m108 from "./108.mp3";
import m109 from "./109.mp3";
import m110 from "./110.mp3";
import m111 from "./111.mp3";
import m112 from "./112.mp3";
import m113 from "./113.mp3";
import m114 from "./114.mp3";
import m115 from "./115.mp3";
import m116 from "./116.mp3";
import m117 from "./117.mp3";
import m118 from "./118.mp3";
import m119 from "./119.mp3";
import m120 from "./120.mp3";
import m121 from "./121.mp3";
import m122 from "./122.mp3";
import m123 from "./123.mp3";
import m124 from "./124.mp3";
import m125 from "./125.mp3";
import m126 from "./126.mp3";
import m127 from "./127.mp3";
import m128 from "./128.mp3";
import m129 from "./129.mp3";
import m130 from "./130.mp3";
import m131 from "./131.mp3";
import m132 from "./132.mp3";
import m133 from "./133.mp3";
import m134 from "./134.mp3";
import m135 from "./135.mp3";
import m136 from "./136.mp3";
import m137 from "./137.mp3";
import m138 from "./138.mp3";
import m139 from "./139.mp3";
import m140 from "./140.mp3";
import m141 from "./141.mp3";
import m142 from "./142.mp3";
import m143 from "./143.mp3";
import m144 from "./144.mp3";
import m145 from "./145.mp3";
import m146 from "./146.mp3";
import m147 from "./147.mp3";
import m148 from "./148.mp3";
import m149 from "./149.mp3";
import m150 from "./150.mp3";
import m151 from "./151.mp3";
import m152 from "./152.mp3";
import m153 from "./153.mp3";
import m154 from "./154.mp3";
const mp3 = [
  m0,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  m8,
  m9,
  m10,
  m11,
  m12,
  m13,
  m14,
  m15,
  m16,
  m17,
  m18,
  m19,
  m20,
  m21,
  m22,
  m23,
  m24,
  m25,
  m26,
  m27,
  m28,
  m29,
  m30,
  m31,
  m32,
  m33,
  m34,
  m35,
  m36,
  m37,
  m38,
  m39,
  m40,
  m41,
  m42,
  m43,
  m44,
  m45,
  m46,
  m47,
  m48,
  m49,
  m50,
  m51,
  m52,
  m53,
  m54,
  m55,
  m56,
  m57,
  m58,
  m59,
  m60,
  m61,
  m62,
  m63,
  m64,
  m65,
  m66,
  m67,
  m68,
  m69,
  m70,
  m71,
  m72,
  m73,
  m74,
  m75,
  m76,
  m77,
  m78,
  m79,
  m80,
  m81,
  m82,
  m83,
  m84,
  m85,
  m86,
  m87,
  m88,
  m89,
  m90,
  m91,
  m92,
  m93,
  m94,
  m95,
  m96,
  m97,
  m98,
  m99,
  m100,
  m101,
  m102,
  m103,
  m104,
  m105,
  m106,
  m107,
  m108,
  m109,
  m110,
  m111,
  m112,
  m113,
  m114,
  m115,
  m116,
  m117,
  m118,
  m119,
  m120,
  m121,
  m122,
  m123,
  m124,
  m125,
  m126,
  m127,
  m128,
  m129,
  m130,
  m131,
  m132,
  m133,
  m134,
  m135,
  m136,
  m137,
  m138,
  m139,
  m140,
  m141,
  m142,
  m143,
  m144,
  m145,
  m146,
  m147,
  m148,
  m149,
  m150,
  m151,
  m152,
  m153,
  m154,
];
export default mp3;
