const a_t12_1 = [
  ["Когда ты обычно ?", "When do you usually ?"],
  ["Почему ты всегда ?", "Why do you always ?"],
  ["Куда она ?", "Where does she ?"],
  ["Что она обычно ?", "What does she usually ?"],
  ["Кого вы всегда ?", "Who d'you always ?"],
  ["Куда они часто ?", "Where do they often ?"],
  ["Что он ?", "What does he ?"],
  ["Почему ты ?", "Why do you ?"],
  ["Как это обычно ?", "How does it usually ?"],
  ["Когда она ?", "When does she ?"],
  ["Что они ?", "What do they ?"],
  ["Где вы всегда ?", "Where d'you always ?"],
  ["Кого ты ?", "Who do you ?"],
  ["Почему он редко ?", "Why does he rarely ?"],
  ["Когда они обычно ?", "When do they usually ?"],
  ["Куда ты всегда ?", "Where do you always ?"],
  ["Почему она часто ?", "Why does she often ?"],
  ["Когда вы обычно ?", "When do you usually ?"],
  ["Куда ты всегда ?", "Where do you always ?"],
  ["Как они всегда ?", "How do they always ?"],
  ["Где ты обычно ?", "Where do you usually ?"],
  ["Кого она обычно ?", "Who does she usually ?"],
  ["Почему они редко ?", "Why do they rarely ?"],
  ["Как это всегда ?", "How does it always ?"],
  ["Кого ты ?", "Who do you ?"],
  ["Как это обычно ?", "How does it usually ?"],
];

export default a_t12_1;
