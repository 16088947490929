const txteng7 = [
  ["я (сейчас) готовлю.", "I'm cooking."],
  ["я (сейчас) хочу приготовить.", "I want to cook."],
  ["мы (сейчас) не работаем. ", "We aren't working."],
  ["мы (сейчас) не знаем.", "We don't know."],
  ["Ты слышышь меня ?", "Can you hear me ?"],
  ["Я не вижу тебя (сейчас)", "I can't see you."],
  ["Она (сейчас) не спит.", "She isn't sleeping."],
  ["Что ты (сейчас) делаешь ?", "What are you doing ?"],
  ["Что ты хочешь (сейчас) ?", "What d'you want ?"],
  ["Что ей нужно (сейчас) ?", "What does she need ?"],
  ["Она покупает машину (сейчас).", "She's buying a car."],
  ["У меня сейчас есть деньги.", "I have money."],
  ["У тебя сейчас есть время?", "D'you have time ?"],
  ["Почему ты сейчас продаешь дом ?", "Why are you selling the house ?"],
  ["Она не верит, что у меня есть деньги.", "She doesn't belive I have money."],
  [
    "Он не знает, что мне это сейчас не нужно.",
    "He doesn't know I don't need it.",
  ],
  ["Он сейчас хочет прийти и увидеть тебя.", "He wants to come and see you."],
  ["Я знаю, что ты сейчас работаешь.", "I know you are working."],
  ["Она знает, что он сейчас не работает.", "She knows he isn't working."],
  ["Я не узнаЮ тебя сейчас.", "I don't recognize you."],
  ["Ты узнаешь меня ?", "D'you recognize me ?"],
  ["Ты сейчас веришь мне ?", "D'you belive me ?"],
  ["Ты идешь туда ?", "Are you going there ?"],
  ["Она знает, что он понимает.", "She knows he understands."],
  [
    "Я сейчас понимаю, что ты знаешь это.",
    "I understand that you know it. (зечюноуит)",
  ],
  ["Я сейчас читаю.", "I'm reading."],
  ["Что ты сейчас знаешь ?", "What d'you know ? (уодьюноу)"],
  ["Что ты сейчас понимаешь ?", "What d'you understand ?"],
  ["Я не думаю, что она любит тебя.", "I don't think that she loves you."],
  [
    "Он думает, что она хочет купить машину.",
    "He thinks she wants to buy a car.",
  ],
  ["Ты знаешь, что мы сейчас работаем.", "You know we are working."],
  ["Она не верит, что он сейчас готовит.", "She doesn't belive he's cooking."],
  ["Мы не слышим вас сейчас.", "We can't hear you."],
  [
    "Они не знают, что ему нужны деньги.",
    "They don't know that he needs money.",
  ],
];

export default txteng7;
