const a_t9 = [
  ["Где они сейчас встречаются?", "Where are they meeting?"],
  ["Что он сейчас начинает?", "What is he beginning?"],
  ["Где она сейчас поет?", "Where is she singing?"],
  ["Почему вы сейчас отправляете?", "Why are you sending?"],
  ["Что мы сейчас даем?", "What are you giving?"],
  ["Как ты сейчас продаешь?", "How are you selling?"],
  ["Что сейчас горит?", "What is burning?"],
  ["Куда они сейчас летят?", "Where are they flying?"],
  ["Что мы сейчас берем?", "What are we taking?"],
  ["Кто сейчас плавает?", "Who is swimming?"],
  ["Как она сейчас спит?", "How is she sleeping?"],
  ["Почему он сейчас покупает?", "Why is he buying?"],
  ["Куда ты сейчас летишь?", "Where are you flying?"],
  ["Почему они сейчас отправляют?", "Why are they selling?"],
  ["Как он сейчас готовит?", "How is he cooking?"],
  ["Что мы сейчас даем?", "What are we giving?"],
  ["Почему ты сейчас начинаешь?", "Why are you begining?"],
];

export default a_t9;
