const txteng3 = [
  ["Кто она?", "Who is she?"],
  ["Кто они?", "Who are they?"],
  ["Кто я?", "Who am I?"],
  ["Кто он?", "Who is he?"],
  ["Кем он работает?", "What is he?"],
  ["Когда они заняты?", "When are they busy?"],
  ["Где мы?", "Where are we?"],
  ["Почему она там?", "Why is she there?"],
  ["Что это?", "What is it?"],
  ["Кто это?", "Who is it?"],
  ["Как это?", "How is it?"],
  ["Кто ты?", "Who are you?"],
  ["Почему они злятся?", "Why are they angry?"],
  ["Чем ты занимаешься?", "What are you?"],
  ["Когда он возвращается?", "When is he back?"],
  ["Где я?", "Where am I?"],
  ["Когда они бывают здесь?", "When are they here?"],
  ["Почему мы возвращаемся?", "Why are we back?"],
  ["Когда он дома?", "When is he home?"],
  ["Где она?", "Where is she?"],
  ["Почему ты занят?", "Why are you busy?"],
];

export default txteng3;
